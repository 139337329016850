import { postSearchGetPriceRangeByBrands } from "@/api";
import { formatLabelString } from "@/common/helper";
import { homeStore, minMaxPriceStore } from "@/store";
import theme from "@/theme/theme";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import styled from "@emotion/styled";
import rtlPlugin from "stylis-plugin-rtl";

import colors from "@/theme/colors";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Typography,
} from "@mui/material";
import { sendGTMEvent } from "@next/third-parties/google";
import { useEffect, useState } from "react";
import NextLink from "./NextLink";

const PickWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 40px;
  margin-top: 60px;
  max-width: 1040px;
  box-shadow: 0px 1px 6px 0px rgba(0, 36, 78, 0.3);
  border-radius: 20px;
  padding: 32px 64px 56px;
  ${theme.breakpoints.down("sm")} {
    margin-top: 10px;
    width: 90%;
  }
`;

const SelectionWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;
const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    gap: 16px;
    max-width: 320px;
  }
`;
const ChooseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${theme.breakpoints.down("sm")} {
    flex-wrap: wrap;
  }
  gap: 20px;
  width: 100%;
  margin-top: 20px;

  .MuiOutlinedInput-notchedOutline {
    height: 43px;
    top: 0;
  }
`;

const ButtonWrapper = styled(Button)`
  width: 100%;
  max-width: 270px;

  a {
    color: white;
    text-decoration: none;
  }
`;

function valuetext(value: number) {
  return `${value}₪`;
}

const CheckWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const PickCar = () => {
  const [value, setValue] = useState<number[]>([]);

  const [firstValues, setFirstValue] = useState<number[]>([]);
  const [chosenBrand, setChosenBrand] = useState<string[]>([]);
  const brands = homeStore().brands;

  const [ids, setIds] = useState<number[]>([]);
  const [searchLink, setSearchLink] = useState<string>("/inventory");

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
    minMaxPriceStore(newValue as number[]);
  };

  const handleChangeChosenBrand = (
    event: SelectChangeEvent<typeof chosenBrand>
  ) => {
    const {
      target: { value },
    } = event;

    setIds(
      brands
        .filter((brand) => (value as string[]).includes(brand.name))
        .map((brand) => brand.id)
    );
    setChosenBrand(typeof value === "string" ? value.split(",") : value);
  };

  const goToModalsPage = () => {
    sendGTMEvent({
      action: "click",
      model_type: chosenBrand,
      event: "search_for_car_click",
    });
    minMaxPriceStore(value);
  };
  useEffect(() => {
    const getUpdatedBrands = async () => {
      let selectedIds;
      if (ids.length === 0) {
        selectedIds = brands.map((brand) => brand.id);
      } else {
        selectedIds = ids;
      }
      const res = await postSearchGetPriceRangeByBrands(selectedIds);
      setFirstValue([res.data.body.minPrice, res.data.body.maxPrice]);
      setValue([res.data.body.minPrice, res.data.body.maxPrice]);
    };
    getUpdatedBrands();
  }, [chosenBrand, ids]);
  useEffect(() => {
    if (chosenBrand.length === 0) {
      setSearchLink(`/inventory?minPrice=${value[0]}&maxPrice=${value[1]}`);
    } else {
      setSearchLink(
        `/inventory?minPrice=${value[0]}&maxPrice=${value[1]}&brandId=${ids}`
      );
    }
  }, [value, firstValues]);

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });

  return (
    <PickWrapper>
      <Typography fontWeight={400} mb={3} variant="h2">
        מה התקציב שלך?
      </Typography>
      <ChooseWrapper>
        <FormControl
          fullWidth
          style={{ marginBottom: 0, height: "43px", width: "270px" }}
        >
          <InputLabel
            sx={{
              inset: "auto 14px auto auto",
              transform: "translate(0, 9px) scale(1)",
            }}
            id="demo-simple-select-label"
          >
            בחירת דגם
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={chosenBrand}
            label="בחר דגם"
            MenuProps={{
              disableScrollLock: true,
            }}
            aria-label="בחר דגם"
            multiple
            onChange={handleChangeChosenBrand}
            renderValue={(selected) => selected.join(", ")}
            sx={{
              textAlign: "right",
              ".MuiSelect-icon": {
                inset: "auto auto auto 8px",
              },
              ".MuiSelect-select": {
                paddingInlineStart: "8px!important",
              },
            }}
          >
            {brands.map((brand) => (
              <MenuItem key={brand.id} value={brand.name}>
                <CheckWrapper>
                  <Checkbox
                    checked={chosenBrand.indexOf(brand.name) > -1}
                    disableRipple
                  />
                  {brand.name}
                </CheckWrapper>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SliderWrapper>
          <Typography fontSize={16} fontWeight={300} color={colors.black}>
            סינון לפי טווח מחירים
          </Typography>
          <CacheProvider value={cacheRtl}>
            <Slider
              disableSwap
              value={value}
              onChange={handleChange}
              max={firstValues[1]}
              min={firstValues[0]}
              step={10000}
              valueLabelFormat={formatLabelString}
              getAriaValueText={valuetext}
              getAriaLabel={(index) =>
                index === 0
                  ? "בחר טווח מחירים מחיר מינימלי"
                  : "בחר טווח מחירים מחיר מקסימלי"
              }
            />
          </CacheProvider>
          <SelectionWrapper>
            <Typography fontSize={12} fontWeight={300} color={colors.black}>
              {"₪" + Number(value[0]).toLocaleString()}
            </Typography>

            <Typography fontSize={12} fontWeight={300} color={colors.black}>
              {"₪" + Number(value[1]).toLocaleString()}
            </Typography>
          </SelectionWrapper>
        </SliderWrapper>
        <NextLink legacyBehavior passHref href={decodeURI(searchLink)}>
          <ButtonWrapper
            onClick={goToModalsPage}
            variant="contained"
            color="primary"
            role="presentation"
          >
            חיפוש
          </ButtonWrapper>
        </NextLink>
      </ChooseWrapper>
    </PickWrapper>
  );
};
